.error {
  color: var(--theme-primary);
  width: var(--content-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5rem auto;
  display: flex;
}

.search-results-preview .error {
  width: auto;
  margin: 0;
}

.error-titre {
  text-align: center;
  font-size: 3.125rem;
}

.search-results-preview .error-titre {
  font-size: 2.5rem;
}

.error-sous-titre {
  color: var(--theme-secondary);
  background-color: var(--theme-on-secondary);
  font-size: 16rem;
  position: relative;
}

.search-results-preview .error-sous-titre {
  font-size: 12.5rem;
}

.error-popup {
  color: var(--theme-on-primary);
  background-color: var(--theme-primary);
  width: 31rem;
  padding: 1em 2em;
  font-size: 1rem;
  display: none;
  position: absolute;
  top: 50%;
  right: 35%;
}

.error-popup:hover, .error-hover-element:hover + .error-popup {
  display: block;
}

.error-content {
  text-align: justify;
  padding: 1em 5em;
  font-size: 2rem;
}

.search-results-preview .error-content {
  font-size: 1.125rem;
}

/*# sourceMappingURL=flatpages.dc6ba70c.css.map */
